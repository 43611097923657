import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch} from 'react-router-dom';
import Routes from './routes/Routes';
import Footer from "./pages/partials/Footer";
// 2023.06.28
// import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Redsalud.css';
import './css/estilo.css';
import Navigation from './pages/partials/Navigation';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Navigation/>
      </Switch>
      </BrowserRouter>
      <main className="main-content container" style={{marginTop: 0, minHeight: 300, marginBottom: 50}}>
        <div style={{marginTop: '170px'}}></div>
        <Routes />
      </main>
    <Footer/>
  </React.StrictMode>,
  document.getElementById('root')
);

