import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faPencilAlt, faPaperPlane, faPlus, faFilePdf, faFilter, faTrash, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import Tabla from '../components/Tabla';
import { Loading } from '../components';
import { getDatatablePam, getEstados, getFiliales, getIsapres, EliminarDatosPam, ExportarPAM  } from '../components/Funciones';
import SelectLista from '../components/SelectLista';
import PamForm from '../components/PamForm';
import Swal from 'sweetalert2';

const DashboardPam = () => {

    const permisos = localStorage.getItem('permisos');
    const is_super = localStorage.getItem('super') === 's';
    const [listaPam, setListaPam] =  useState([]);
    const [listaEstados, setListaEstados] =  useState([]);
    const [listaIsapres, setListaIsapres] =  useState([]);
    const [listaFiliales, setListaFiliales] =  useState([]);
    const datenow = new Date().toISOString().slice(0, 10);
    const [filtros, setFiltros] =  useState({
      estado: '',
      filial: '',
      isapre: '',
      fecha: datenow,
      fecha_hasta: datenow,
    });
    
    if (permisos.includes('editar_pam') !== false ) {
        console.log(permisos.includes('editar_pam'));
    }


    const { handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {
  		  data.pam =  handleEliminar.id;
  		    //guardar(eliminar);
        console.log(data.pam)
    };

    const guardar = (data) => {
      console.log(data);
  		EliminarDatosPam(data)
      .then(response => {
			if(!response.error){
        Swal.fire({
          title: 'Eliminando PAM...',
          allowOutsideClick: false,
          timer: 3000
        });
        Swal.showLoading();
        //const timer = setTimeout(() => window.location.reload(false), 3000);

			}

        }).catch(errorMessage => {
            alert(errorMessage);
        });

  	}
    const handleEliminar = (e) => {
      let id = parseInt(e.currentTarget.id);
      const eliminar = ({id: id})
      const obj = JSON.stringify(eliminar)
      guardar(obj);
      // EliminarDatosPam(obj);

      //console.log(eliminar);


    }


  const agregarClick = (e) => {
    // Obtener botón que recibió el clic
    let btn = e.currentTarget.id;

    console.log(btn);
}



    const titulo_tabla = "Lista de Registros PAM";
    const columns   = [
      /*{
          name: 'ID',
          selector: 'id',
          sortable: true,
          filterable: true
      },*/
      {
          name: 'ID Cuenta',
          selector: 'id_cuenta',
          sortable: true,
          filterable: true
      },
      {
          name: 'Estado',
          selector: 'estado',
          sortable: true
      },
      {
          name: 'Fecha',
          selector: 'fecha',
          sortable: true,
          grow: 2
      },
      {
          name: 'Filial',
          selector: 'filial',
          sortable: true
      },
      {
          name: 'RUT Paciente',
          selector: 'rut_paciente',
          sortable: true,
          filterable: true
      },
      {
          name: 'Paciente',
          selector: 'nombre_paciente',
          sortable: true,
          grow: 2
      },
     {
          name: 'Tipo de PAM',
          selector: 'tipo_actividad',
          sortable: true
     },
      {
          name: 'Responsable',
          selector: 'usuario',
          sortable: true
      },
      {
        name: 'Accion',
        cell: row => (
          <>

            { ( (permisos.includes('ver_pam') || is_super) && row.pdf) && (
              <button
                className="btn btn-sm btn-danger"
                onClick={verPdf}
                title="Ver PAM"
                id={row.id}
                url={row.pdf}
              >
                  <FontAwesomeIcon icon={faFilePdf}/>

              </button>
            )}
            { (

              (permisos.includes('editar_pam') && row.pdf == null)
              || (permisos.includes('regenerar_pdf') )
              ) && (
              <button
                className="btn btn-sm btn-info"
                onClick={handleEditar}
                title="Editar"
                id={row.id}
              >
                  <FontAwesomeIcon icon={faPencilAlt} data-toggle="modal" data-target="#ModalFiliales"/>

              </button>
            )}
            { (

              (permisos.includes('eliminar_pam') === true)
              ) && (

              <button
                className="btn btn-sm btn-warning"
                title="Eliminar"
                data-toggle="modal"
                data-target={"#ModalEliminarPam"+row.id}
                id={row.id}
              >
                  <FontAwesomeIcon icon={faTrash} />
              </button>

            )}

            <div className="modal fade" id={"ModalEliminarPam"+row.id} tabIndex={-1} role="dialog" aria-labelledby="ModalEliminarPam" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header" style={{ backgroundColor: '#099',color: '#fff',padding: '10px'}}>
                    <h5 className="modal-title" id="ModalEliminarPamLabel">Eliminar PAM</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body text-left">
                    <form onSubmit={handleSubmit(onSubmit)}>

                      <h2 style={{marginLeft:'160px'}}>¿Deseas Eliminar PAM?</h2>

                      <div className="form-group">
                        <input
                          name="pam"
                          type="hidden"
                          className="form-control"
                          id={row.id}

                        />
                      </div>


                      <div className="col-xs-12 text-center">
                        <button type="button" className="btn btn-danger" data-dismiss="modal" id={row.id} onClick={agregarClick}>Cancelar</button>

                        <input type="submit" className="btn btn-secondary" value="Confirmar" id={row.id} onClick={handleEliminar} style={{marginLeft: '5px'}}/>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                  </div>
                </div>
              </div>
            </div>

          </>
        )

      }
    ];

    useEffect(() => {
      getEstadosPam();
      getIsapresPam();
      getFilialesLista();
      getTablaPam();

      setTimeout( () => {
              document.getElementsByName('RUT Paciente')[0]?.setAttribute('placeholder', 'Filtrar');
              document.getElementsByName('ID Cuenta')[0]?.setAttribute('placeholder', 'Filtrar');
          }, 4000
      );

    }, []);

    const getTablaPam = () => {
      getDatatablePam(filtros)
      .then(json => setListaPam(json));
    }

    const getIsapresPam = () => {
      getIsapres()
      .then(json => {
          json.datos.push({id: 99, value: '', nombre: 'Seleccione', label: "Seleccione"});
          setListaIsapres(json.datos)
      });
    }

    const getEstadosPam = () => {
      getEstados()
        .then(json => {
            json.datos.push({id: 99, value: '', nombre: 'Seleccione', label: "Seleccione"});
            setListaEstados(json.datos)
        });
    }

    const getFilialesLista = () => {
      getFiliales()
        .then(json => {
            json.datos.push({id: 99, value: '', nombre: 'Seleccione', label: "Seleccione"});
            setListaFiliales(json.datos)
        });
    }


    const handleEditar = (e) => {
      let id = parseInt(e.currentTarget.id);
      window.location.href = '/formulario/pam/' + id;
    }



    const verPdf = (e) => {

      const obj = e.currentTarget.attributes;
      const enlace = obj.url.value;
      console.log(obj);
      window.open(enlace, '_blank');
    }

    const buscarId = (array, id) => {
      let tmpRegistroPam =null;
      let datos = array.data;
      for ( let index = 0; index < datos.length; index++) {
        if( datos[index].id === id) {
          tmpRegistroPam = datos[index]
        }
      }
      return tmpRegistroPam;
    }

    const handleChangeFiltros = (input) => e => {
      const valor = e.value;
      setFiltros({
        ...filtros,
        [input] : valor
      });

    }

    const handleChangeFiltros_Fecha = (input) => e => {
      const valor = e.target.value;
      setFiltros({
        ...filtros,
        [input] : valor
      });

    }

    const handleChangeFiltros_rut = (input) => e => {
      const valor = e.target.value;
      setFiltros({
        ...filtros,
        [input] : valor
      });

    }

    const handleChangeFiltros_id = (input) => e => {
      const valor = e.target.value;
      setFiltros({
        ...filtros,
        [input] : valor
      });

    }
    //funciones para exportar 
    const [exportar, setExportar] =  useState({
      id: '',
      desde: '',
      hasta: '',
      rut: '',
    });

    const handleChangeExportar= (input) => e => {
      const valor = e.target.value;
      setExportar({
        ...exportar,
        [input] : valor
      });

    }

    const onExport = data => {
      data.id = exportar ? exportar.id : '';
      guardarCSV(exportar);
    };
    
    const guardarCSV = (data) => {
      ExportarPAM(data)
      .then(response => {
        if(!response.error){
          console.log(response);
          //const filename = response.archivo
          //const csv = atob(response.contenido).replaceAll('"', '').replaceAll(';', ',');
          //const filename = 'pam_datos_exportados.csv';
          //const csv = response.replaceAll('"', '').replaceAll(';', ',');

          function descargar(response) {
            //const universalBOM = "\uFEFF";
            //csv = universalBOM + csv;

            console.log(response);
            var element = document.createElement('a');
            element.setAttribute('href', response);

            //element.setAttribute('download', filename);
            element.click(console.log("se descargo el archivo"));
          }
          Swal.fire({
            title: 'Exportando PAM...',
            allowOutsideClick: false,
            timer:3000
          });
          Swal.showLoading();
          //const timer = setTimeout(() => window.location.reload(false), 3000);
          descargar(response);
        }
        
      }).catch(errorMessage => {
        alert(errorMessage);
      });
    }
    const filtos = (
      <>
        <div className="col-xs-2 text-left" style={{marginLeft:"20px"}}>
          <SelectLista
            titulo="Estado"
            nombreCampo="estado"
            datos={listaEstados}
            modal=""
            handleChange={handleChangeFiltros('estado')}
            valor={filtros && filtros.estado}
          />
        </div>
        <div className="col-xs-2 text-left" style={{marginLeft:"20px"}}>
            <SelectLista
              titulo="Isapre"
              nombreCampo="isapre"
              datos={listaIsapres}
              modal=""
              handleChange={handleChangeFiltros('isapre')}
              valor={filtros && filtros.isapre}
            />
        </div>
        <div className="col-xs-2 text-left" style={{marginLeft:"20px"}}>
            <SelectLista
              titulo="Filial"
              nombreCampo="filial"
              datos={listaFiliales}
              modal=""
              handleChange={handleChangeFiltros('filial')}
              valor={filtros && filtros.filial}
            />
        </div>
        <div className="col-xs-2 text-left" style={{marginLeft:"20px"}}>
            <div className="form-group">
              <label>Fecha Desde</label>
              <input
                name="fecha"
                type="date"
                className="form-control"
                onChange={handleChangeFiltros_Fecha('fecha')}
                value={filtros && filtros.fecha}
              />
            </div>
        </div>
        <div className="col-xs-2 text-left" style={{marginLeft:"20px"}}>
            <div className="form-group">
              <label>Fecha Hasta</label>
              <input
                name="fecha_hasta"
                type="date"
                className="form-control"
                onChange={handleChangeFiltros_Fecha('fecha_hasta')}
                value={filtros && filtros.fecha_hasta}
              />
            </div>
        </div>
        <div className="col-xs-12 text-right">

        {(permisos.includes('exportar_pam') || is_super) && (
          <>
            <button type="button" className="btn btn-sm btn-export" data-target="#ModalExportar" data-toggle="modal">
              <FontAwesomeIcon icon={faFileCsv}/>  Exportar
            </button>
          </>
        )}
          <button type="button" className="btn btn-sm btn-info" onClick={getTablaPam} style={{marginLeft: '5px'}}>
            <FontAwesomeIcon icon={faFilter}/>  Filtrar
          </button>
          
        </div>

        <div className="modal fade" id="ModalExportar" tabIndex={-1} role="dialog" aria-labelledby="ModalEliminarPam" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header" style={{ backgroundColor: '#099',color: '#fff',padding: '10px'}}>
                    <h5 className="modal-title" id="ModalEliminarPamLabel">Exportar Listado PAM</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body text-left">
                    <form onSubmit={handleSubmit(onExport)}>

                      <h2 style={{marginLeft:'160px'}}>Ingrese rango de fecha </h2>

                      <div className="form-group">
                        <label htmlFor="Desde"> Desde <span style={{color:"red"}}>*</span></label>
                              <input 
                                  type="date" 
                                  name="desde"
                                  className="form-control" 
                                  onChange={handleChangeExportar('desde')}
                                  value={exportar && exportar.desde}
                              />
                          <label htmlFor="hasta"> Hasta <span style={{color:"red"}}>*</span></label>
                            <input 
                                  type="date" 
                                  name="hasta"
                                  className="form-control" 
                                  onChange={handleChangeExportar('hasta')}
                                  value={exportar && exportar.hasta}
                            /> 

                          <label htmlFor="hasta"> RUT </label>
                                <input 
                                      type="text" 
                                      name="rut"
                                      className="form-control" 
                                      onChange={handleChangeExportar('rut')}
                                      value={exportar && exportar.rut}
                            /> 
                          <label htmlFor="hasta"> ID </label>
                                <input 
                                      type="text" 
                                      name="id"
                                      className="form-control" 
                                      onChange={handleChangeExportar('id')}
                                      value={exportar && exportar.id}
                            /> 
                      </div>
                      <div className="col-xs-12 text-center">
                        <button type="button" className="btn btn-danger" data-dismiss="modal" >Cancelar</button>

                        <input type="submit" className="btn btn-secondary" value="Confirmar" style={{marginLeft: '5px'}}/>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                  </div>
                </div>
              </div>
            </div>
      </>
    );
    return (
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="panel panel-default panel-wide">
          <div className="panel-body">
              <h3><FontAwesomeIcon icon={faUsers}/> Registros PAM</h3>

              <div className="row">
                {filtos}
                 <div className="col-md-12">
                  {
                    listaPam !== [] ?
                      <Tabla datos={listaPam} columns={columns} handleEditar={handleEditar} titulo={titulo_tabla} serverSide={false}/>
                    :
                    <Loading/>
                    }
                 </div>
              </div>
          </div>
        </div>
      </div>
    )

}

export default DashboardPam
